body {
  font-family: "Nixie One", cursive !important;
}
.text__primary {
  color: #2fb30f !important;
}
.row {
  --bs-gutter-x: 0 !important;
}
.header {
  // position: absolute;
  // margin-top: 150px;
  .header-row {
    width: 98vw;
  }
  .img-wrapper {
    max-width: 400px !important;
    margin-left: auto;
    margin-right: auto;
  }
  button {
    width: 150px;
    background: transparent !important;
    background-position: center;
    background-size: cover;
    border: 3px solid #2fb30f !important;
    p {
      color: #2fb30f !important;
      margin: 0%;
      font-size: 14px;
      font-weight: 600;
    }
    h6 {
      color: #2fb30f;
      font-size: 20px;
      font-weight: bold;
    }
  }
  .social-links {
    margin-top: 50px;
    i {
      color: #000;
      background-color: #fff !important;
      border-radius: 30px;
      padding: 15px 6px;
    }
  }
}

@media only screen and (max-width: 800px) {
  .header {
    .header-row {
      width: 97vw !important;
    }
    .header-image {
      visibility: hidden;
      width: 50px !important;
    }
  }
}
@media only screen and (max-width: 650px) {
  .header {
    button {
      width: 120px;
      height: 80px;
    }
    .header-row {
      width: 95vw !important;
    }
    .header-image {
      visibility: hidden;
      width: 20px !important;
    }
  }
}
