.contain-components .bg-video {
  width: 100%;
  left: 0;
  top: 0;
  height: max-content;
  position: absolute;
  z-index: -1;
  object-fit: cover;
}

.contain-components {
  /* background: url('https://mememaniadapp.netlify.app/images/background.png'); */
  background: #000;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 20px;
  min-height: 100vh;
}
