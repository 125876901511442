@import url("https://fonts.googleapis.com/css2?family=Nixie+One&display=swap");
/* font-family: 'Nixie One', cursive; */
.Nav-bar {
  /* position: absolute; */
  width: 100%;
}

.contain-logo .logo-bg {
  width: 200px;
  cursor: pointer;
}
.logo {
  position: absolute;
  left: 50px;
  top: 20px;
  cursor: pointer;
}
.contain-nav-data ul {
  display: flex;
  align-items: center;
}
.contain-nav-data {
  display: flex;
  align-items: center;
}
.connect-btn {
  position: absolute;
  right: 35px;
  top: 12px;
}

.connect {
  position: absolute;
  top: 34px;
  right: 65px;
  font-family: "Nixie One";
  font-size: 20px;
  font-weight: 800;
  color: black;
  cursor: pointer;
}
.nav-center {
  display: flex;
  width: 900px;
  justify-content: center;
}
.nav-center a {
  margin-left: 10%;
  margin-right: 10%;
  font-family: "Nixie One", cursive;
  font-size: 20px;
  font-style: normal;
  font-weight: 200;
  color: #fffbeb;
  cursor: pointer;
}

.nav-links {
  gap: 100px;
}
.nav-links a {
  text-decoration: none;
  color: #d6d3c7;
  font-size: 20px;
}

.navbar-row {
  padding: 15px;
}
.navbar-row-large button {
  width: 200px;
  height: 100px;
  padding: 0% !important;
  background: transparent !important;
  background-color: transparent !important;
  border: none !important;
  background-position: center;
  background-size: cover;
  border: none;
}
.navbar-row-large button:hover {
  padding: 5px;
  border: none;
  background-color: transparent;
}
.navbar-row-large button p {
  color: black;
  margin: 0%;
  font-size: 20px;
}
.connect-button {
  width: 140px;
  height: 80px;
}

.navbar-row-small {
  background-color: rgba(0, 0, 0, 0.658);
  color: white;
  font-weight: bold;
  padding: 20px;
}
.toggle-button {
  background-color: transparent;
  border: none;
}
.toggle-button:hover {
  background-color: transparent;
  border: none;
}
.dropdown-menu {
  background-color: rgba(0, 0, 0, 0.452);
  text-align: center;
  margin-top: 30px;
}
.dropdown-item {
  color: white;
}
